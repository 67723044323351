<template>
  <canvas id="canvas"></canvas>
</template>

<script>
import {Game} from "@/js/game";

export default {
  name: 'App',
  components: {},
  mounted() {
    if (window.game){
      window.game.engine.dispose()
    }
    const game = new Game(document.getElementById("canvas"))

  }
}
</script>


<style>
* {
  margin: 0;
  padding: 0;
}

html, body {

}

canvas {
  height: 100%;
  width: 100%;
  position: absolute;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
