import {
    ActionManager,
    ArcRotateCamera, Camera,
    CubeTexture,
    Engine, ExecuteCodeAction,
    InputBlock, Mesh,
    MeshBuilder,
    NodeMaterial,
    PBRMaterial, PhotoDome,
    Scene,
    SceneLoader, TransformNode, Vector3
} from "@babylonjs/core";
import "@babylonjs/loaders"
import "@babylonjs/inspector"

export class Game {
    constructor(canvas: HTMLCanvasElement) {
        this.engine = new Engine(canvas)
        this.scene = new Scene(this.engine)

        this.createScene().then(e => {
            this.engine.runRenderLoop(e => {
                this.scene.render()
            })
        })

        window.addEventListener("resize", e => {
            this.engine.resize()
        })

        document.addEventListener("keydown", e => {
            if (e.key.toLowerCase() === "i") {
                this.scene.debugLayer.show({overlay: true}).then()
            }
        })

    }

    async createScene() {
        let scene = this.scene
        let testBox = MeshBuilder.CreateBox("test box", {})


        await SceneLoader.ImportMeshAsync('', "", './云展厅.glb', scene)

        scene.materials.forEach(e => {
            if (e instanceof PBRMaterial) {
                e.metallic = 0
            }
        })

        let mat = await NodeMaterial.ParseFromSnippetAsync("PS02BE#2")
        testBox.material = mat
        let mark1 = scene.meshes.filter(e => e.name.indexOf("[mark1]") !== -1)
        let link1 = scene.meshes.find(e => e.name.indexOf("[link1]") !== -1)
        let link2 = scene.meshes.find(e => e.name.indexOf("[link2]") !== -1)
        let link3 = scene.meshes.find(e => e.name.indexOf("[link3]") !== -1)
        let link4 = scene.meshes.find(e => e.name.indexOf("[link4]") !== -1)
        let link5 = scene.meshes.find(e => e.name.indexOf("[link5]") !== -1)
        let link6 = scene.meshes.find(e => e.name.indexOf("[link6]") !== -1)


        let typeMesh = scene.meshes.filter(e => e.name.indexOf("typeMesh") !== -1)
        typeMesh.forEach(e => e.visibility = false)

        mark1.forEach(e => e.material = mat)

        link1.visibility = false
        link1.actionManager = new ActionManager()
        link1.actionManager.registerAction(new ExecuteCodeAction(
            ActionManager.OnPickDownTrigger,
            e => {
              
            }
        ))
        
        link2.visibility = false
        link2.actionManager = new ActionManager()
        link2.actionManager.registerAction(new ExecuteCodeAction(
            ActionManager.OnDoublePickTrigger,
            e => {
                window.open("https://demo1.ky3d.com/")
            }
        ))

        link3.visibility = false
        link3.actionManager = new ActionManager()
        link3.actionManager.registerAction(new ExecuteCodeAction(
            ActionManager.OnDoublePickTrigger,
            e => {
                window.open("https://zh1.ky3d.com/?tem_id=37&mch_id=29")
            }
        ))

        link4.visibility = false
        link4.actionManager = new ActionManager()
        link4.actionManager.registerAction(new ExecuteCodeAction(
            ActionManager.OnDoublePickTrigger,
            e => {
                window.open("https://zh.ky3d.com/")
            }
        ))

        link5.visibility = false
        link5.actionManager = new ActionManager()
        link5.actionManager.registerAction(new ExecuteCodeAction(
            ActionManager.OnDoublePickTrigger,
            e => {
              
            }
        ))

        link6.visibility = false
        link6.actionManager = new ActionManager()
        link6.actionManager.registerAction(new ExecuteCodeAction(
            ActionManager.OnDoublePickTrigger,
            e => {
               
            }
        ))

        scene.createDefaultCamera(1, 1, 1)
        let camera: ArcRotateCamera = scene.activeCamera
        camera.panningSensibility = 0
        camera.target = Vector3.Zero()
        camera.lowerRadiusLimit = 100
        camera.upperRadiusLimit = 300

        scene.createDefaultEnvironment({
            environmentTexture: "environment.env",
            createGround: false,
            createSkybox: false,
            // skyboxTexture: "sky.jpg",
        })

        let photoDome = new PhotoDome("360", "sky.jpg", {size: 5000, resolution: 32}, this.scene)
    }
}
